import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ProductContext } from "./ProductContext"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QuantityBtn from './QuantityBtn';
import ProductInfoText from './ProductInfoText';
import OffcanvasNavbar from './OffcanvasNavbar';
import Footer from './Footer';

import { motion } from "framer-motion";

export default function ProductDetail() {
    let params = useParams()
    let [productDetail, setProductDetail] = useState(null)

    /*     useEffect(() => {
            fetch('https://fanman95.github.io/Json/Product.json')
                .then(response => response.json())
                .then(data => {
                    let productInfo = data.find((element) => {
                        return element.id === parseInt(params.id)
                    })
                    setProductDetail(productInfo)
                })
        }, [params.id]) */

    const { productData } = useContext(ProductContext)

    useEffect(() => {
        if (productData === null) return
        let productInfo = productData.find((element) => {
            return element.id === parseInt(params.id)
        })
        setProductDetail(productInfo)
    }, [productData, params.id])

    const container = {
        hidden: { opacity: 1, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20,
                delayChildren: 0.2,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    return (
        <>
            <OffcanvasNavbar />
            <Container>
                <Row>
                    <Col md={6} sm={12}>
                        {
                            productDetail &&
                            <motion.div variants={container} initial="hidden" animate="visible">
                                <motion.img variants={item} src={process.env.PUBLIC_URL + '/img/' + productDetail.image}
                                    alt={productDetail.name}
                                    style={{ width: "100%" }} />
                                <motion.div variants={item}>
                                    <QuantityBtn productInfo={productDetail} />
                                </motion.div>
                            </motion.div>
                        }
                    </Col>
                    <Col md={6} sm={12}>
                        {
                            productDetail &&
                            <motion.div variants={container} initial="hidden" animate="visible" className='my-customer-flexbox my-customer-h100 my-customer-w100'>
                                <div style={{ width: "100%" }}>
                                    <motion.div variants={item}><ProductInfoText TextHeading="作品" Text={productDetail.workTitle} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="商品規定" Text={productDetail.specification} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="售價" Text={productDetail.price} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="名稱" Text={productDetail.productName} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="廠商" Text={productDetail.factory} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="發售日" Text={productDetail.releaseDate} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="截訂日" Text={productDetail.preOrderLastDate} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="官方網站" Text={<a href={productDetail.website}>Link</a>} /></motion.div>
                                    <motion.div variants={item}><ProductInfoText TextHeading="描述" Text={productDetail.description} /></motion.div>
                                </div>
                            </motion.div>
                        }
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}
