import React, { useContext } from 'react'
import { CartContext } from "./CartContext"
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductInfoText from './ProductInfoText';
import OffcanvasNavbar from './OffcanvasNavbar';
import QuantityBtn from './QuantityBtn'

import { motion } from "framer-motion";

export default function CheckOut() {
    let { cartItems } = useContext(CartContext)
    let cartEmtry = (cartItems.length === 0 ? false : true)
    let priceTotal = cartItems.reduce((total, product) => {
        return total += product.price * product.quantity
    }, 0)

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20,
                delayChildren: 0.2,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    return (
        <>
            <OffcanvasNavbar />

            {cartEmtry &&
                <div>
                    {cartItems.map((product) => {
                        return (
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md={6} sm={12} className="my-customer-flexbox">
                                        <motion.div variants={container} initial="hidden" animate="visible" key={product.id} >
                                            <img src={process.env.PUBLIC_URL + '/img/' + product.image} alt="" style={{ width: "100%" }} ></img>
                                        </motion.div>
                                    </Col>
                                    <Col md={6} sm={12} className="my-customer-flexbox">
                                        <motion.div variants={container} initial="hidden" animate="visible" key={product.id} className="my-customer-w100">
                                            <motion.div variants={item}>
                                                <ProductInfoText TextHeading="名稱" Text={product.name} />
                                            </motion.div>
                                            <motion.div variants={item}>
                                                <ProductInfoText TextHeading="售價" Text={product.price} />
                                            </motion.div>
                                            <motion.div variants={item}>
                                                <ProductInfoText TextHeading="數量" Text={product.quantity} />
                                            </motion.div>
                                            <motion.div variants={item}>
                                                <QuantityBtn productInfo={product} />
                                            </motion.div>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })
                    }
                    <Container>
                        <Row>
                            <h1 style={{ textAlign: "end" }}>Total: JPD ${priceTotal}</h1>
                        </Row>
                    </Container>
                </div>
            }

            {
                !cartEmtry &&
                <div className='my-customer-flexbox my-customer-w100' style={{ height: "100vh", flexDirection: "column" }}>
                    <div className='my-customer-flexbox' style={{ transform: "scale(0.5)", flexDirection: "column" }}>
                        <div className='my-customer-cartHandle'></div>
                        <div className='my-customer-cartBody'></div>
                    </div>
                    <p>Your cart is empty</p>
                    <Link to="/">
                        <button className='my-customer-active-btn' style={{ borderRadius: "10px" }}>Back to Shop</button>
                    </Link>
                </div>
            }
        </>
    )
}
