import React from 'react'
import CardModule from './Card'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function CardWrap({ cardNum_1, cardNum_2, cardNum_3, cardNum_4 }) {
    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={3} sm={12} style={{ margin: "0.5em 0 0.5em 0" }}>
                    <CardModule cardNum={cardNum_1}/>
                </Col>
                <Col md={3} sm={12} style={{ margin: "0.5em 0 0.5em 0" }}>
                    <CardModule cardNum={cardNum_2}/>
                </Col>
                <Col md={3} sm={12} style={{ margin: "0.5em 0 0.5em 0" }}>
                    <CardModule cardNum={cardNum_3}/>
                </Col>
                <Col md={3} sm={12} style={{ margin: "0.5em 0 0.5em 0" }}>
                    <CardModule cardNum={cardNum_4}/>
                </Col>
            </Row>
        </Container>

    )
}
