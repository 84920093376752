import React, { useState } from 'react'
import OffcanvasNavbar from './OffcanvasNavbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";

export default function FactoryPage() {

    return (
        <>
            <div style={{
                background: "linear-gradient(250deg, #7b2ff7, #f107a3)",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                padding: 0,
                margin: 0,
                boxSizing: "border-box"
            }}>
                <OffcanvasNavbar />
                <AnimateSharedLayout>
                    <motion.ul layout initial={{ borderRadius: 25 }}
                        style={{
                            width: "90%",
                            display: "flex",
                            flexDirection: "column",
                            background: "#fff",
                            padding: "20px",
                            borderRadius: "30px"
                        }}
                    >
                        {factoryInfo.map(item => (
                            <Item key={item.id} itemProp={item.id} />
                        ))}
                    </motion.ul>
                </AnimateSharedLayout>
            </div>
        </>
    );
}

function Item({ itemProp }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <motion.li layout onClick={toggleOpen} initial={{ borderRadius: 10 }}
            style={{
                backgroundColor: "rgba(200, 200, 200, 0.5)",
                borderRadius: "10px",
                padding: "20px",
                margin: "10px",
                overflow: "hidden",
                cursor: "pointer",
            }}
        >
            <img
                className="d-block"
                style={{ width: "200px", margin: "10px" }}
                src={process.env.PUBLIC_URL + '/img/' + factoryInfo[itemProp].img}
                alt="First slide"
            />
            <AnimatePresence>{isOpen && <Content contentProp={itemProp} />}</AnimatePresence>
        </motion.li>
    );
}

function Content({ contentProp }) {
    return (
        <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <h1 style={{ fontSize: "2em" }}>Factory: {factoryInfo[contentProp].name}</h1>
            <div style={{ width: "100%", height: "8px", backgroundColor: "#999", borderRadius: "10px", marginTop: "12px" }} />
            <div style={{ width: "100%", height: "8px", backgroundColor: "#999", borderRadius: "10px", marginTop: "12px" }} />
            <div style={{ width: "100%", height: "8px", backgroundColor: "#999", borderRadius: "10px", marginTop: "12px" }} />
            <div style={{ width: "100%", height: "8px", backgroundColor: "#999", borderRadius: "10px", marginTop: "12px" }} />
            <div style={{ width: "100%", height: "8px", backgroundColor: "#999", borderRadius: "10px", marginTop: "12px" }} />

        </motion.div>
    );
}

const factoryInfo = [
    {
        id: 0,
        name: "Good Smile Company",
        img: "logo_gsc.png"
    },
    {
        id: 1,
        name: "Alter",
        img: "logo-ci@2x.png"
    },
    {
        id: 2,
        name: "KOTOBUKIYA",
        img: "logo-KOTOBUKIYA.png"
    },
    {
        id: 3,
        name: "Freeing",
        img: "logo-Freeing.png"
    },
    {
        id: 4,
        name: "Bandai",
        img: "bandainamco_logo.png"
    }
]

