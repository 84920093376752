import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'

export default function Footer() {
    return (
        <Container fluid style={{ background: "var(--main-black)", height: "200px" }} className="my-customer-flexbox">
            <Row>
                <Col>
                    <p style={{ color: "var(--main-white)" }}>Copyright © 2022 Tom Fan</p>
                </Col>
            </Row>
        </Container>
    )
}
