import React from 'react'

export default function ProductInfoText({ TextHeading, Text }) {
    return (
        <>
            <div style={{
                width: "100%",
                display: "flex",
                margin: "0.1em 0 0.1em 0",
            }}>
                <div className='my-customer-flexbox'
                    style={{
                        width: '30%',
                        backgroundColor: "var(--main-black)",
                        padding: "0.5em"
                    }}
                >
                    <p style={{ color: "var(--main-white)", textAlign: "center", margin: 0, padding: 0 }}>{TextHeading}</p>
                </div>

                <div className='my-customer-flexbox'
                    style={{
                        width: '70%',
                        backgroundColor: "var(--main-grey)",
                        padding: "0.5em"
                    }}
                >
                    <p style={{ margin: 0, padding: 0 }}>{Text}</p>
                </div>

            </div>
        </>
    )
}
