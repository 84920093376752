import React from 'react'
import OffcanvasNavbar from './OffcanvasNavbar'

export default function NewReleasePage() {
    return (
        <>
            <OffcanvasNavbar />
        </>
    )
}
