import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom'
import { BsFillCartCheckFill, BsQuestionCircleFill, BsFillBellFill, BsBuilding, BsFillStarFill, BsFillHexagonFill } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import Logo from './Logo';
import CreateUser from './CreateUser'

import { motion } from "framer-motion";

export default function OffcanvasNavbar() {
    const [navOpacity, setNavOpacity] = useState(0.5)

    function handleScroll() {
        const show = window.scrollY
        if (show > 100) {
            setNavOpacity(1)
        }
        else {
            setNavOpacity(0.5)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            handleScroll()
        })
    }, [])

    const container = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20
            }
        }
    };

    return (
        <div>
            {[false].map((expand) => (
                <Navbar key={expand} bg="light" expand={expand} className="mb-3" fixed="top"
                    style={{ opacity: navOpacity }} >
                    <Container fluid>
                        <Navbar.Brand as={NavLink} to='/'>
                            <Logo scale="0.1" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                                </Offcanvas.Title>
                            </Offcanvas.Header>

                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <motion.div variants={container} initial="hidden" animate="visible">
                                        <Nav.Link as={NavLink} to='/'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-purple'>
                                                <AiFillHome style={{ fontSize: "2em" }} /><p>Home</p>
                                            </div>
                                        </Nav.Link>

                                        <Nav.Link as={NavLink} to='/NewReleasePage'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-orange'>
                                                <BsFillBellFill style={{ fontSize: "2em" }} /><p>New Releases</p>
                                            </div>
                                        </Nav.Link>

                                        <Nav.Link as={NavLink} to='/FiguresPage'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-orange'>
                                                <BsFillStarFill style={{ fontSize: "2em" }} /><p>Figures</p>
                                            </div>
                                        </Nav.Link>

                                        <Nav.Link as={NavLink} to='/ToysPage'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-orange'>
                                                <BsFillHexagonFill style={{ fontSize: "2em" }} /><p>Toys</p>
                                            </div>
                                        </Nav.Link>

                                        <Nav.Link as={NavLink} to='/FactoryPage'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-blue'>
                                                <BsBuilding style={{ fontSize: "2em" }} /><p>Factory</p>
                                            </div>
                                        </Nav.Link>

                                        <Nav.Link as={NavLink} to='/CheckOut'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-green'>
                                                <BsFillCartCheckFill style={{ fontSize: "2em" }} /><p>Check out</p>
                                            </div>
                                        </Nav.Link>

                                        <Nav.Link as={NavLink} to='/HelpPage'>
                                            <div className='my-customer-flexbox-column dark-style iconHover-red'>
                                                <BsQuestionCircleFill style={{ fontSize: "2em" }} /><p>Help?</p>
                                            </div>
                                        </Nav.Link>
                                        
                                        <CreateUser />
                                    </motion.div>
                                </Nav>

                            </Offcanvas.Body>

                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    )
}
