import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Logo from './Logo';

export default function CardWrapHeading({ heading }) {
    return (
        <Container>
            <Row>
                <div className='my-customer-w100'
                    style={{ backgroundColor: 'var(--main-black)', 
                    margin: "1em 0 1em 0", 
                    padding: "0.5em",
                    display:"flex",
                    alignItems: "center" }}>

                    <Logo scale={0.1} />

                    <h1 style={{
                        color: "var(--main-white)",
                        fontSize: "1.5em",
                        margin: "0 0.5em 0 0.5em",
                        padding: 0
                    }}>
                        {heading}
                    </h1>
                </div>
            </Row>
        </Container>
    )
}
