import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { ProductContext } from "./ProductContext"

import Card from 'react-bootstrap/Card';

export default function CardModule({ cardNum }) {

    let [cardInfo, setCardInfo] = useState(null)
    const { productData } = useContext(ProductContext)

    /*     useEffect(() => {
            fetch('https://fanman95.github.io/Json/Product.json')
                .then(response => response.json())
                .then(data => {
                    let info = data.find((element) => {
                        return element.id === parseInt(cardNum)
                    })
                    setCardInfo(info)
                })
        }, [cardNum]) */

    useEffect(() => {
        let info = productData.find((element) => {
            return element.id === parseInt(cardNum)
        })
        setCardInfo(info)
    }, [cardNum, productData])

    return (
        <Card className='w100 h-100'>
            {cardInfo &&
                <div>
                    <Link to={'/ProductDetail/' + cardInfo.id}>
                        <div style={{width: '100%', aspectRatio: '1/1', overflow: 'hidden'}}>
                            <Card.Img variant="top" src={process.env.PUBLIC_URL + '/img/' + cardInfo.image} className='my-customer-hover-scale120' />
                        </div>
                    </Link>
                    <Card.Body>
                        <Card.Title>{cardInfo.productName}</Card.Title>
                        <Card.Text>{cardInfo.workTitle}</Card.Text>
                    </Card.Body>

                    <Card.Body>
                        {cardInfo.preOrder &&
                            <p>接受預訂</p>
                        }

                        {!cardInfo.preOrder &&
                            <p>截止預訂</p>
                        }
                        <p style={{textAlign: "right"}}>${cardInfo.price}</p>
                    </Card.Body>
                </div>
            }
        </Card>
    )
}
