import React from 'react'
import OffcanvasNavbar from './OffcanvasNavbar'

export default function HelpPage() {
    return (
        <>
            <OffcanvasNavbar />
        </>
    )
}
