import React, { useContext, useState } from 'react'
import { CartContext } from "./CartContext"
import Button from 'react-bootstrap/Button';

import { BsFillCartCheckFill } from "react-icons/bs";

import { motion } from "framer-motion";

export default function QuantityBtn({ productInfo }) {

    const { cartItems, setCartItems } = useContext(CartContext)

    let productIndexInCart = cartItems.findIndex((element) => {
        return element.id === productInfo.id
    })

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart === -1) ? 0 : cartItems[productIndexInCart].quantity
    )

    const handleAdd = () => {
        if (productIndexInCart === -1) {
            setCartItems(
                [{
                    id: productInfo.id,
                    name: productInfo.productName,
                    image: productInfo.image,
                    price: productInfo.price,
                    description: productInfo.description,
                    releaseDate: productInfo.releaseDate,
                    quantity: 1
                },
                ...cartItems]
            )
        }
        else {
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity++
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart + 1)
    }

    const handleSubtract = () => {
        if (cartItems[productIndexInCart].quantity === 1) {
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart, 1)
            setCartItems(newCartArray)
        }
        else {
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart - 1)
    }

    return (
        <>
            <div style={{
                border: "1px solid var(--main-black)",
                background: "var(--main-grey)",
                margin: "1em"
            }}
                className='my-customer-flexbox-column my-customer-padding-1em'>
                <div className='my-customer-flexbox my-customer-w100'>
                    <BsFillCartCheckFill style={{ fontSize: '2.5em', color: "var(--sub-green)" }} />
                    <p className='my-customer-margin-padding-0'>Shopping Cart</p>
                </div>

                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    padding: "1em"
                }}>
                    <motion.div
                        whileHover={{ scale: 1.2 }}
                        whileTap={{
                            scale: 0.8,
                            rotate: 360,
                            borderRadius: "100%"
                        }}>
                        <Button variant="primary"
                            className='my-customer-flexbox'
                            style={{ background: "var(--main-black)" }}
                            onClick={handleSubtract}>
                            <div>-</div>
                        </Button>
                    </motion.div>

                    <div>{numInCart}</div>

                    <motion.div
                        whileHover={{ scale: 1.2 }}
                        whileTap={{
                            scale: 0.8,
                            rotate: 360,
                            borderRadius: "100%"
                        }}>
                        <Button variant="primary"
                            className='my-customer-flexbox'
                            style={{ background: "var(--main-black)" }}
                            onClick={handleAdd}>
                            <div>+</div>
                        </Button>
                    </motion.div>
                </div>
            </div>
        </>
    )
}
