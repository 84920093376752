import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CartContext } from './CartContext'
import { useEffect, useState } from 'react';
import { ProductContext } from './ProductContext';

import Main from './Main.jsx';
import ProductDetail from './ProductDetail';
import CheckOut from './CheckOut';
import NewReleasePage from './NewReleasePage';
import HelpPage from './HelpPage';
import ToysPage from './ToysPage';
import FactoryPage from './FactoryPage';
import FiguresPage from './FiguresPage';

function App() {
  const [cartItems, setCartItems] = useState([])
  const [productData, setProductData] = useState(null)

  useEffect(() => {
    fetch('https://fanman95.github.io/Json/Product.json')
      .then(response => response.json())
      .then(data => setProductData(data))
  }, [])

  return (
    <BrowserRouter>
      <ProductContext.Provider value={{ productData, setProductData }}>
        <CartContext.Provider value={{ cartItems, setCartItems }}>
          <Routes>

            <Route path="/" element={<Main />}>
            </Route>

            <Route path="/ProductDetail" element={<ProductDetail />}>
              <Route path=":id" element={<ProductDetail />} />
            </Route>

            <Route path="/NewReleasePage" element={<NewReleasePage />}>
            </Route>

            <Route path="/FiguresPage" element={<FiguresPage />}>
            </Route>

            <Route path="/ToysPage" element={<ToysPage />}>
            </Route>

            <Route path="/FactoryPage" element={<FactoryPage />}>
            </Route>

            <Route path="/HelpPage" element={<HelpPage />}>
            </Route>

            <Route path="/CheckOut" element={<CheckOut />}>
            </Route>

            <Route path="/*" element={<p>404</p>}>
            </Route>
          </Routes>
        </CartContext.Provider>
      </ProductContext.Provider>
    </BrowserRouter >
  );
}

export default App;
