import React, { useEffect, useState } from 'react'

export default function Logo({ scale }) {
    /*  Egg setting
        width: "300px",
        height: "400px",
        background: "#ffffff",
        borderRadius: "50% / 60% 60% 40% 40%",
        boxShadow: "-5px -30px 120px #000000 inset,\
        -5px 20px 40px rgba(0, 0, 0, 0.5)",
        transform: "scale(0.1)" 
    */

    const [logoWidth, setLogoWidth] = useState(300)
    const [logoHeight, setLogoHeight] = useState(400)
    const [logoInsetBoxShadow, setLogoInsetBoxShadow] = useState("-5px -30px 120px #000000 inset")
    const [logoBoxShadow, setLogoBoxShadow] = useState("-5px 20px 40px rgba(0, 0, 0, 0.5)")
    const [eggHover, seteggHover] = useState(false)

    function handleMouseEnter() {
        seteggHover(true)
    }

    function handleMouseLeave() {
        seteggHover(false)
    }

    useEffect(() => {
        const calcScale = Number(scale)
        const calcLogoWidth = 300 * calcScale
        const calcLogoHeight = 400 * calcScale
        const calcLogoInsetBoxShadowParm1 = -5 * calcScale
        const calcLogoInsetBoxShadowParm2 = -30 * calcScale
        const calcLogoInsetBoxShadowParm3 = 120 * calcScale
        const calcLogoBoxShadowParm1 = -5 * calcScale
        const calcLogoBoxShadowParm2 = 20 * calcScale
        const calcLogoBoxShadowParm3 = 40 * calcScale

        setLogoWidth(calcLogoWidth)
        setLogoHeight(calcLogoHeight)
        setLogoInsetBoxShadow(calcLogoInsetBoxShadowParm1 + "px " + calcLogoInsetBoxShadowParm2 + "px " + calcLogoInsetBoxShadowParm3 + "px #000000 inset")
        setLogoBoxShadow(calcLogoBoxShadowParm1 + "px " + calcLogoBoxShadowParm2 + "px " + calcLogoBoxShadowParm3 + "px rgba(0, 0, 0, 0.5)")
    }, [scale])

    return (
        <>
            <div className='egg'
                style={{
                    width: logoWidth + "px",
                    height: logoHeight + "px",
                    background: eggHover === false ? "var(--main-white)" : "#" + Math.floor(Math.random() * 16777215).toString(16),
                    borderRadius: "50% / 60% 60% 40% 40%",
                    boxShadow: logoInsetBoxShadow + "," + logoBoxShadow
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            ></div>
        </>
    )
}
