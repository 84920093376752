import React, { useEffect, useState, useContext } from 'react'
import { ProductContext } from "./ProductContext"

import CarouselModule from './Carousel'
import CardWrap from './CardWrap'
import SnowCanvas from './SnowCanvas'
import CardWrapHeading from './CardWrapHeading'
import OffcanvasNavbar from './OffcanvasNavbar'

import Footer from './Footer'
import { motion } from "framer-motion";

export default function Main() {

    const [newReleasesList, setNewReleasesList] = useState(null)
    const [newFigureList, setNewFigureList] = useState(null)
    const [newNendoroidList, setNewNendoroidList] = useState(null)

    const { productData } = useContext(ProductContext)

    /* 
        useEffect(() => {
            fetch('https://fanman95.github.io/Json/Product.json')
                .then(response => response.json())
                .then(data => {
                    const newProduct = [...data]
                    setNewReleasesList(newProduct.reverse())
    
                    let figureInfo = data.filter((element) => {
                        return element.category === "Figure"
                    })
                    setNewFigureList(figureInfo.reverse())
    
                    let nendoroidInfo = data.filter((element) => {
                        return element.category === "Nendoroid"
                    })
                    setNewNendoroidList(nendoroidInfo.reverse())
    
                })
        }, []) */

    useEffect(() => {
        if (productData === null) return
        const newProduct = [...productData]
        setNewReleasesList(newProduct.reverse())

        const figureInfo = productData.filter((element) => {
            return element.category === "Figure"
        })
        setNewFigureList(figureInfo.reverse())

        const nendoroidInfo = productData.filter((element) => {
            return element.category === "Nendoroid"
        })
        setNewNendoroidList(nendoroidInfo.reverse())
    }, [productData])

    const container = {
        hidden: { opacity: 1, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delayChildren: 0.2,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    return (
        <>
            <OffcanvasNavbar />
            <CarouselModule />
            <SnowCanvas />

            <motion.div variants={container} initial="hidden" animate="visible" >
                <motion.div variants={item}>
                    <CardWrapHeading heading="New Releases" />
                </motion.div>
                <motion.div variants={item}>
                    {newReleasesList &&
                        <CardWrap cardNum_1={newReleasesList[0].id} cardNum_2={newReleasesList[1].id} cardNum_3={newReleasesList[2].id} cardNum_4={newReleasesList[3].id} />
                    }
                </motion.div>
                <motion.div variants={item}>
                    {newReleasesList &&
                        <CardWrap cardNum_1={newReleasesList[4].id} cardNum_2={newReleasesList[5].id} cardNum_3={newReleasesList[6].id} cardNum_4={newReleasesList[7].id} />
                    }
                </motion.div>
                {!newReleasesList && <p>Sorry, no data was found from server</p>}
            </motion.div>

            <div>
                <CardWrapHeading heading="New Figure" />
                {newFigureList &&
                    <CardWrap cardNum_1={newFigureList[0].id} cardNum_2={newFigureList[1].id} cardNum_3={newFigureList[0].id} cardNum_4={newFigureList[1].id} />
                }
            </div>

            <div>
                <CardWrapHeading heading="New Nendoroid" />
                {newNendoroidList &&
                    <CardWrap cardNum_1={newNendoroidList[0].id} cardNum_2={newNendoroidList[1].id} cardNum_3={newNendoroidList[2].id} cardNum_4={newNendoroidList[3].id} />
                }
            </div>

            <div>
                <CardWrapHeading heading="New Figma" />
            </div>

            <div>
                <CardWrapHeading heading="New Model" />
            </div>

            <Footer />
        </>
    )
}