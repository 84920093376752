import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';

export default function CarouselModule() {

    return (
        <Carousel>
            <Carousel.Item interval={2000} >
                <img
                    className="d-block w-100"
                    src={process.env.PUBLIC_URL + '/img/03_img_0.jpg'}
                    alt="First slide"
                />
            </Carousel.Item>

            <Carousel.Item interval={1500}>
                <img
                    className="d-block w-100"
                    src={process.env.PUBLIC_URL + '/img/03_img_0.jpg'}
                    alt="Second slide"
                />
            </Carousel.Item>

            <Carousel.Item interval={1000}>
                <img
                    className="d-block w-100"
                    src={process.env.PUBLIC_URL + '/img/03_img_0.jpg'}
                    alt="Third slide"
                />
            </Carousel.Item>

            <Carousel.Item interval={500}>
                <img
                    className="d-block w-100"
                    src={process.env.PUBLIC_URL + '/img/03_img_0.jpg'}
                    alt="Fourth slide"
                />
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={process.env.PUBLIC_URL + '/img/03_img_0.jpg'}
                    alt="Fifth slide"
                />
            </Carousel.Item>
        </Carousel>
    )
}