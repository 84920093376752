import React, { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

export default function CreateUser() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const inputUser = useRef(null)
    const inputPassword = useRef(null)
    const inputConfirmPassword = useRef(null)
    const inputEmail = useRef(null)

    const [checkUserAction, setcheckUserAction] = useState(null)
    const [checkUserLength, setCheckUserLength] = useState(null)
    const [checkUserNotSameAsPw, setcheckUserNotSameAsPw] = useState(true)
    const [checkPwLength, setCheckPwLength] = useState(null)
    const [checkPwUpperCase, setCheckPwUpperCase] = useState(null)
    const [checkPwNum, setCheckPwNum] = useState(null)
    const [checkPwSymbol, setCheckPwSymbol] = useState(null)
    const [checkCheckBox, setcheckCheckBox] = useState(null)
    const [checkConfirmPw, setCheckConfirmPw] = useState(null)
    const [checkEmail, setCheckEmail] = useState(null)

    const upperCaseLetters = /[A-Z]+/g
    const numbers = /[0-9]+/g
    const symbols = /[$@#&!]+/g
    const emailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

    const handleSubmit = (e) => {
        e.preventDefault()

        //check all condition
        if (checkUserLength === true &&
            checkUserNotSameAsPw === true &&
            checkPwLength === true &&
            checkPwUpperCase === true &&
            checkPwNum === true &&
            checkPwSymbol === true &&
            checkCheckBox === true &&
            checkConfirmPw === true &&
            checkEmail === true
        ) {
            alert("Success")
        }
    }

    const handleClick = () => {
        setcheckCheckBox(checkCheckBox => !checkCheckBox)
    }

    const handleKeyUp = () => {
        setcheckUserAction(true)
        //Validation
        //user length > 0?
        if (inputUser.current.value.length > 0) {
            setCheckUserLength(true)
        }
        else {
            setCheckUserLength(false)
        }
        //user != pw?
        if (inputUser.current.value !== inputPassword.current.value) {
            setcheckUserNotSameAsPw(true)
        }
        else {
            setcheckUserNotSameAsPw(false)
        }
        //pw length > 6?
        if (inputPassword.current.value.length >= 6) {
            setCheckPwLength(true)
        }
        else {
            setCheckPwLength(false)
        }
        //pw included Capital?
        if (inputPassword.current.value.match(upperCaseLetters)) {
            setCheckPwUpperCase(true)
        }
        else {
            setCheckPwUpperCase(false)
        }
        //pw included Number?
        if (inputPassword.current.value.match(numbers)) {
            setCheckPwNum(true)
        }
        else {
            setCheckPwNum(false)
        }
        //pw included Symbols?
        if (inputPassword.current.value.match(symbols)) {
            setCheckPwSymbol(true)
        }
        else {
            setCheckPwSymbol(false)
        }
        //confirm pw = pw ?
        if (inputConfirmPassword.current.value === inputPassword.current.value && inputConfirmPassword.current.value.length > 0 && inputPassword.current.value.length > 0) {
            setCheckConfirmPw(true)
        }
        else {
            setCheckConfirmPw(false)
        }
        //check email
        if (inputEmail.current.value.match(emailRegularExpression)) {
            setCheckEmail(true)
        }
        else {
            setCheckEmail(false)
        }

    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Login In
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onKeyUp={handleKeyUp} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Create account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicUser">
                            <Form.Label>Login ID</Form.Label>
                            <Form.Control type="ID" placeholder="Login ID" ref={inputUser} />
                            {!checkUserLength && checkUserAction &&
                                <Form.Text className="text-muted">
                                    <div style={{ color: "red" }}>@Login ID can not be empty</div>
                                </Form.Text>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" autoFocus ref={inputEmail} />
                            <Form.Text className="text-muted">
                                <div style={{ color: (checkEmail === true ? "green" : "") }}>@correct email</div>
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" ref={inputPassword} />
                            <Form.Text className="text-muted">
                                <div style={{ color: (checkPwLength === true ? "green" : "") }}>@contains at least 6 characters</div>
                                <div style={{ color: (checkPwUpperCase === true ? "green" : "") }}>@contains upper case letters</div>
                                <div style={{ color: (checkPwNum === true ? "green" : "") }}>@contains number</div>
                                <div style={{ color: (checkPwSymbol === true ? "green" : "") }}>@contains symbol $@#&!</div>
                            </Form.Text>
                            {!checkUserNotSameAsPw && checkUserAction &&
                                <Form.Text className="text-muted">
                                    <div style={{ color: "red" }}>@Password cannot same as Login ID</div>
                                </Form.Text>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm password" ref={inputConfirmPassword} />
                            <Form.Text className="text-muted" >
                                <div style={{ color: (checkConfirmPw === true ? "green" : "") }}>@confirm password equal to password</div>
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="I agree to the terms and conditions" onClick={handleClick} />
                        </Form.Group>

                        <Button variant="primary" type="submit" onSubmit={handleSubmit}>
                            Continue
                        </Button>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
