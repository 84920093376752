import { React, useEffect, useRef } from 'react'

export default function SnowCanvas() {

    const canvasRef = useRef(null);

    useEffect(() => {
        const c = canvasRef.current;
        const ctx = c.getContext('2d');

        c.width = window.innerWidth;
        c.height = window.innerHeight;

        function getRandom(min, max) {
            return Math.random() * (max - min) + min;
        }

        class ball {
            constructor(x, y, r, vx, vy, color) {
                this.position = {
                    x: x,
                    y: y
                }
                this.velocity = {
                    x: vx,
                    y: vy
                }
                this.r = r
                this.color = color
            }
            draw() {
                var grd = ctx.createRadialGradient(this.position.x, this.position.y, this.r, this.position.x, this.position.y, this.r / 1.25);
                grd.addColorStop(1.000, this.color);
                grd.addColorStop(0.000, 'rgba(255, 250, 250, 0)');

                // Fill with gradient
                ctx.fillStyle = grd;

                ctx.beginPath();
                ctx.arc(this.position.x, this.position.y, this.r, 0, 2 * Math.PI, false);
                ctx.fill();
            }
            update() {
                if (this.position.x > c.width + this.r || this.position.y > c.height + this.r) {
                    var random = Math.random()

                    if (random > 0.5) {
                        this.position.x = -this.r;
                        this.position.y = getRandom(0, c.height);

                    } else {
                        this.position.x = getRandom(0, c.width);
                        this.position.y = -this.r;

                    }
                }
                else {
                    this.draw()
                }
                this.position.x += this.velocity.x
                this.position.y += this.velocity.y
            }
        }

        const Balls = []
        //Ball setting
        let minRadius = 10
        let maxRadius = 30
        let minSpeed = 0.05
        let maxSpeed = 0.5
        let minOpacity = 0.005
        let maxOpacity = 0.5
        let colors = [
            '147, 231, 251',
            '192, 246, 251',
            '255, 250, 250',
            '224, 255, 255'
        ]

        function init() {
            for (var i = 0; i < 20; i++) {
                let randomColorNum = Math.floor(getRandom(0, colors.length - 1))
                console.log(colors[randomColorNum])
                Balls.push(new ball(
                    getRandom(0, c.width),
                    getRandom(0, c.height),
                    getRandom(minRadius, maxRadius),
                    getRandom(minSpeed, maxSpeed),
                    getRandom(minSpeed, maxSpeed),
                    'rgba(' + colors[randomColorNum] + ',' + getRandom(minOpacity, maxOpacity) + ')'))
            }
        }

        function animate() {
            requestAnimationFrame(animate);
            ctx.clearRect(0, 0, c.width, c.height)
            Balls.forEach((x) => {
                x.update()
            })
        };
        animate();
        init();

        window.addEventListener('resize', () => {
            c.width = window.innerWidth;
            c.height = window.innerHeight;
        })

    }, [])

    return (
        <canvas
            ref={canvasRef}
            style={{ background: 'transparent', position: "fixed", top: 0, left: 0, margin: 0, padding: 0, pointerEvents: "none"}}
        >
        </canvas>
    )
}